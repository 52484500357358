import Vue from 'vue'
import axios from 'axios'
import VueFormulate from '@braid/vue-formulate'
import App from './App.vue'
import router from './router'
import AOS from 'aos';
import 'aos/dist/aos.css';
import "./assets/css/style.scss"

import { library } from '@fortawesome/fontawesome-svg-core'
import { faXmark, faBars } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faXmark, faBars, faInstagram, faFacebook)

Vue.component('font-awesome-icon', FontAwesomeIcon)

const axios_instance = axios.create({
  baseURL: Vue.config.devtools ? 'http://localhost:8080/api/' : process.env.VUE_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
})

Vue.prototype.$http = axios_instance;

Vue.prototype.$AOS = AOS;

Vue.config.productionTip = false

Vue.use(VueFormulate)

AOS.init();

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
