<template>
  <div id="hero" :class="{ 'is-top': top }" :style="{ minHeight: height, 'background-image': 'linear-gradient(to bottom, rgba(0, 0, 0, 0.7), ' + gradientColor + '), url(' + require(`../assets/images/${background}`) + ')' }">
    <div class="container">
      <div data-aos="fade-right" data-aos-delay="300" class="hero-text">
        <h1>{{ title }}</h1>
        <p>{{ content }}</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Hero',
  props: ['background', 'gradientColor', 'title', 'content', 'height', 'top'],
  components: {
  },
}
</script>

<style lang="scss" scoped>
  #hero {
    width: 100%;
    background-size: cover;
    background-position: center;
    padding: 40px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

    &.is-top {
      padding-top: 110px;
    }

    .container {
      width: 100%;
      max-width: $site-max-width;
      text-align: center;

      @media only screen and (min-width: 800px) {
        text-align: left;
      }

      .hero-text {
        display: inline-block;
      }

      h1 {
        max-width: 600px;
        text-transform: uppercase;
        font-size: 2.5em;
        margin-bottom: 10px;
        margin-top: 0;
      }

      p {
        margin: 0;
        font-weight: lighter;
        max-width: 800px;
        font-size: 1.2em;
        line-height: 1.5em;
      }

      @media only screen and (min-width: 800px) {
        h1 {
          font-size: 3em;
        }

        p {
          font-size: 1.3em;
        }
      }
    }
  }
</style>