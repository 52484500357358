<template>
  <div class="home">
    <Hero
      :top="true"
      height="100vh"
      background="about.jpg"
      gradientColor="#D32F2F8C"
      title="Who We Are"
      content="My name is Alex Richardson and I Love cars! Since I was a child, I’ve always been captivated by automobiles. With a true love for all different genres and types of vehicles I soon became passionate about Japanese cars. After seeing one of the first S15 Silvias to cruise the streets of Victoria, I seized the opportunity to buy my first right hand drive vehicle, a Spec S S15 Silvia. Since then with the help of friends and fueled by my passion I began learning the process of importing cars and parts from Japan. Over the years of bringing in cars and parts for my own hobbies I learned a great deal, making tons of connections and finding the best deals through every step of the process. Finally, I’ve begun helping others to do the same and share in my excitement of the JDM lifestyle."
    />
    <!-- <Gallery /> -->
    <Footer />
  </div>
</template>

<script>
import Hero from '@/components/Hero.vue'
// import Gallery from '@/components/Gallery.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'About',
  components: {
    Hero,
    // Gallery,
    Footer
  },
  mounted() {  
    setTimeout(() => { this.$AOS.refresh(); }, 500);
  }
}
</script>

