<template>
  <div  class="contact-form">
    <div class="container">
       <div v-if="!messageSuccess">
        <h2 class="form-title">Contact Us</h2>
        <FormulateForm id="contact-form" v-model="formValues" #default="{ isLoading }" @submit="sendMessage">
          <FormulateInput
            name="name"
            label="What is your name?"
            validation="required"
          />
          <FormulateInput
            name="email"
            label="Your Email"
            validation="required|email"
          />
          <FormulateInput
            name="subject"
            label="Reason for contacting us?"
            type="select"
            :options="{import: 'Interested in importing a car from Japan', parts: 'Part Inquiry', other: 'Other'}"
            placeholder="Select an option"
            validation="required"
          />
          <FormulateInput
            type="textarea"
            name="message"
            label="Let us know how we can help you!"
            validation="required"
          />
          <FormulateInput
            type="submit"
            label="Send Message"
            :disabled="isLoading"
          />
        </FormulateForm>
      </div>
      <div v-else class="success">
        <h3>Your message has been sent. We'll get back to you as soon as we can!</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactForm',
  data() {
    return {
      formValues: {},
      messageSuccess: false,
      isLoading: false,
    }
  },
  methods: {
    async sendMessage (data) {
      this.isLoading = true;
      const mailer = await this.$http.post('/mailer/send', data);
      if (mailer.status == 200) this.messageSuccess = true; this.isLoading = false;
    }
  }
}
</script>

<style lang="scss">
  label {
      text-transform: uppercase;
    }
</style>

<style scoped lang="scss">
  .contact-form {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), #311B92), url(../assets/images/contact.jpg);
    background-size: cover;
    background-position: center;
    padding: 20px 20px;
    padding-top: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    position: relative;

    .container {
      width: 100%;
      max-width: $site-max-width;
      background: $text-color-light;
      box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
      padding: 20px;

      .success {
        text-align: center;
      }

      @media only screen and (min-width: 1000px) {
        padding: 50px;
      }

      .form-title {
        text-transform: uppercase;
        font-size: 2em;
        margin-top: 0;
        margin-bottom: 50px;
      }

      #contact-form::v-deep .formulate-input .formulate-input-element {
        max-width: none;
      }

      #contact-form::v-deep button {
        background: $accent-color !important;
        border: none !important;
        border-radius: 0;
        outline: none !important;
        font-weight: bolder !important;
        text-transform: uppercase;

        &:disabled {
          opacity: 0.4;
        }
      }
    }
  }
</style>
