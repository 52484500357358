<template>
  <div class="home">
    <Hero
      :top="true"
      height="400px"
      background="auctions.jpg"
      gradientColor="#8E24AABF"
      title="Auctions"
      content="This is the purchasing method most used and talked about when buying JDM cars. All across Japan there are many auctions houses. As one of the most widely used ways that people in Japan sell cars, the auctions are a great way to find the car you’ve been looking for. With my help we will find and purchase the car you're after. Auction purchases tend to take a little longer as we have to wait for the car we want to be listed, and then try to acquire it at a budget that makes sense for you, the customer."
    />
    <Hero
      height="400px"
      background="wholesale.jpg"
      gradientColor="rgba(255, 255, 255, 0.8)"
      title="Wholesale"
      content="With a network of sellers and friends across Japan, it sometimes makes sense to buy privately or from dealers in Japan instead of auction. Prices can range greatly, but it can speed up the process if we find the right vehicle instead of waiting to win an auction."
    />
    <Hero
      height="400px"
      background="parts.jpg"
      gradientColor="#8E24AABF"
      title="Parts"
      content="If you're looking for parts from Japan I have multiple ways of sourcing and shipping them to Canada that might make sense, both financially, and timing wise."
    />
  </div>
</template>

<script>
import Hero from '@/components/Hero.vue'

export default {
  name: 'Services',
  components: {
    Hero
  }
}
</script>
