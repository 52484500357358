<template>
  <div class="home">
    <Hero
      :top="true"
      height="80vh"
      background="home.jpg"
      gradientColor="#03a9f48C"
      title="Your new source for Auto imports and parts from Japan."
      content="Based in Victoria, BC, with years of experience and connections from Japan to North America, we can make your dream a reality."
    />
  <Service
      height="300px"
      background="wholesale.jpg"
      gradientColor="#8E24AABF"
      title="Auctions"
      content="This is the purchasing method most used and talked about when buying JDM cars. All across Japan there are many auctions houses. As one of the most widely used ways that people in Japan sell cars, the auctions are a great way to find the car you’ve been looking for. With my help we will find and purchase the car you're after. Auction purchases tend to take a little longer as we have to wait for the car we want to be listed, and then try to acquire it at a budget that makes sense for you, the customer."
    />
  <Footer />

  </div>
</template>

<script>
import Hero from '@/components/Hero.vue'
import Service from '@/components/Service.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Hero,
    Service,
    Footer
  },
  mounted() {
    setTimeout(() => { this.$AOS.refresh(); }, 500);
  }
}
</script>

<style lang="scss" scoped>
  .services {
    width: 100%;
    padding: 50px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

    .container {
      width: 100%;
      max-width: $site-max-width;
      text-align: center;

      h2 {
        max-width: 600px;
        color: white;
        text-transform: uppercase;
        font-size: 2em;
        margin: auto;
        margin-bottom: 0;
        margin-top: 0;
      }
    }
  }
</style>
