<template>
  <div id="app">
    <Navbar/>
    <transition name="fade">
      <router-view id="view"></router-view>
    </transition>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'

export default {
  name: 'Home',
  components: {
    Navbar
  },
  created(){
    document.title = "AR Full Auto"
  }
}
</script>


<style lang="scss" scoped>
  #app {
  }

  .fade-enter-active, .fade-leave-active {
    transition-property: opacity ease-in-out;
    transition-duration: .3s;
  }

  .fade-enter-active {
    transition-delay: .3s;
  }

  .fade-enter, .fade-leave-active {
    opacity: 0
  }
</style>
