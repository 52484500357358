<template>
  <div id="footer">
    <div class="container">
      <h3>Find us on social media!</h3>
      <div class="socials">
        <a href="https://www.facebook.com/AR-Full-Auto-102234089135439" target="_blank"><font-awesome-icon icon="fa-brands fa-facebook" /></a>
        <a href="https://www.instagram.com/ar_fullauto/" target="_blank"><font-awesome-icon icon="fa-brands fa-instagram" /></a>
      </div>
      <div class="legal">Copyright © 2022 AR Full Auto. All Rights Reserved.</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Footer',
  components: {
  },
}
</script>

<style lang="scss" scoped>
  #footer {
    display: flex;
    justify-content: center;
    color: $text-color-light;
    padding: 20px;
    text-align: center;

    @media only screen and (min-width: 800px) {
      text-align: left;
    }

    .container {
      width: 100%;
      max-width: $site-max-width;

      h3 {
        text-transform: uppercase;
        font-size: 1.5em;
        margin-bottom: 10px;
      }

      .socials {
        font-size: 2.5em;

        a {
          margin-right: 15px;
          transition: all 0.2s ease-in-out;

          &:hover {
            color: $accent-color;
          }
        }
      }

      .legal {
        margin-top: 200px;
        text-transform: uppercase;
        font-size: 0.7em;
        font-weight: lighter;
        // text-align: center;
      }
    }
  }
</style>
