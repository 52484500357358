<template>
  <div id="navbar" :class="{ scrolled: scroll > 50}">
    <div class="logo"><img src="@/assets/logo.png"/></div>
    <router-link v-for="link in mainLinks" :key="link.to" class="nav-link" :to="link.to">{{link.title}}</router-link>
    <router-link class="contact-link" to="/contact">Contact</router-link>
    <div class="mobile-nav-burger" @click="toggleMobileNav"><font-awesome-icon icon="fa-solid fa-bars" /></div>
    <Transition name="fade">
      <div v-if="showMobileNav" @click="toggleMobileNav" class="mobile-navbar-background"></div>
    </Transition>
    <Transition name="slide">
    <div class="mobile-navbar-container" v-if="showMobileNav">
      <div class="mobile-navbar">
        <div class="navbar-head">
          <font-awesome-icon icon="fa-solid fa-xmark" @click="toggleMobileNav" />
        </div>
        <router-link v-for="link in links" :key="link.to" class="mobile-nav-link" @click.native="toggleMobileNav" :to="link.to">{{link.title}}</router-link>
      </div>
    </div>
    </Transition>
  </div>
</template>

<script>

export default {
  name: 'Navbar',
  components: {
  },
  data() {
    return {
      showMobileNav: false,
      scroll: null,
      links: [
        {
          to: '/',
          title: 'Home'
        },
        {
          to: '/about',
          title: 'About'
        },
        {
          to: '/contact',
          title: 'Contact'
        },
      ],
    }
  },
  computed: {
    mainLinks() {
      return this.links.filter(link => link.title !== 'Contact')
    }
  },
  methods: {
    toggleMobileNav() {
      this.showMobileNav = !this.showMobileNav;
    },
    handleScroll() {
      this.scroll = window.scrollY || window.scrollTop
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
</script>

<style lang="scss" scoped>

  #navbar {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 70px;
    max-width: $site-max-width;
    margin: auto;
    color: $text-color-dark;
    background: $text-color-light;
    user-select: none;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    display: flex;
    align-items: center;
    transition: none;
    transition: all 0.2s ease-in-out;

    @media only screen and (min-width: $site-max-width) {
      top: 20px;
      left: 20px;
      right: 20px;
    }

    &.scrolled {
      top: 0px;
    }

    .logo {
      // position: relative;
      height: 100%;
      padding: 10px 20px;

      img {
        height: 100%;
      }
    }

    .nav-link {
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 1em;
      font-weight: bolder;
      transition: all 0.2s ease-in-out;
      height: 100%;
      display: flex;
      padding: 0 20px;
      align-items: center;

      &.router-link-exact-active, &:hover {
        background:$accent-color;
        color: $text-color-light;
      }
    }

    .contact-link {
      margin-left: auto;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 1em;
      font-weight: bolder;
      transition: all 0.2s ease-in-out;
      height: 45px;
      margin-right: 13px;
      display: flex;
      padding: 0 20px;
      align-items: center;
      color: white;
      background: $accent-color;
      box-sizing: border-box;
      border: 2px solid $accent-color;
      
      &:hover {
        color: white;
        background: $accent-color;
      }
    }

    .mobile-nav-burger {
      display: none;
    }
  }

  @media only screen and (max-width: 600px) {
    #navbar {
      top: 0;
      left: 0;
      right: 0;

      .logo {
        padding: 20px;
        position: relative;

        img {
          height: 100%;
        }
      }

      .nav-link, .contact-link {
        display: none;
      }

      .mobile-nav-burger {
        display: inline-block;
        padding: 20px;
        margin-left: auto;
        cursor: pointer;
        font-size: 1.5em;
      }

      .mobile-navbar-background {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
      }

      .mobile-navbar-container {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        width: 60%;
        min-width: 250px;
        background: red;

        .mobile-navbar {
          position: absolute;
          right: 0;
          width: 100%;
          min-height: 100%;
          background: $text-color-light;
          display: flex;flex-direction: column;
          transition: all 0.2s ease-in-out;

          .navbar-head {
            height: 70px;
            display: flex;
            align-items: center;
            padding: 20px;
            font-size: 1.5em;

            svg {
              cursor: pointer;
            }
          }

          .mobile-nav-link {
            display: inline-block;
            padding: 20px;
            font-weight: bolder;
            text-transform: uppercase;
            letter-spacing: 2px;
            transition: 0.2s all ease-in-out;

            &.router-link-exact-active, &:hover {
              background: $accent-color;
              color: $text-color-light;
            }
          }
        }
      }
    } 
  }

  .slide-enter-active,
  .slide-leave-active {
    transition: all 0.3s ease-in-out;
  }

  .slide-enter,
  .slide-leave-to {
    transform: translateX(100%);
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.3s ease-in-out;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
