<template>
  <div class="contact">
    <ContactForm />
    <Footer />
  </div>
</template>

<script>
import ContactForm from '@/components/ContactForm.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Contact',
  components: {
    ContactForm,
    Footer
  }
}
</script>

<style lang="scss" scoped>
</style>
